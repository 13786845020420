/* General styles */
.checkout-section{
  display: flex;
  padding: 20px;
}
.checkout-wrapper {
    width: 48%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .summary-wrapper{
    width: 48%;
  }
  
  .checkout-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-field {
    margin-bottom: 15px;
  }
  
  .form-field label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
  }
  
  .form-field input,
  .form-field select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .cart-summary {
    margin-top: 20px;
  }
  
  .summary-title {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .summary-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .summary-table th,
  .summary-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .total-price {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .checkout-wrapper {
      padding: 15px;
    }
  
    .form-field input,
    .form-field select,
    .submit-btn {
      font-size: 16px;
    }
  
    .summary-table th,
    .summary-table td {
      font-size: 14px;
      padding: 8px;
    }
  
    .total-price {
      font-size: 16px;
    }
  }
  
  