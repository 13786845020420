/* body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
} */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://in.images.search.yahoo.com/search/images;_ylt=AwrKA4uGOZ5mrLcEfCm7HAx.;_ylu=Y29sbwNzZzMEcG9zAzEEdnRpZAMEc2VjA3BpdnM-?p=login+page+background+images&fr2=piv-web&type=E210IN885G0&fr=mcafee#id=27&iurl=https%3A%2F%2Fwallpaperaccess.com%2Ffull%2F1900851.png&action=click');
  background-size: cover;
  background-position: center;
}

.login-form-wrapper {
  background: rgba(255, 255, 255, 0.85);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.login-heading {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-field {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: #1D4F9B;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: #6a11cb;
}

.signup-prompt {
  margin-top: 15px;
  font-size: 14px;
  color: #333;
}

.signup-link {
  color: #2575fc;
  text-decoration: none;
}

.signup-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 480px) {
  .login-form-wrapper {
      padding: 20px;
  }
}

/* Password Visibility Toggle */
.password-field {
  position: relative;
}

.form-input {
  width: calc(100% - 80px);
}

.password-toggle-btn {
  position: absolute;
  right: 0px;
  top: 10px;
  height: 100%;
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 22px;
  padding: 0 15px;
}
