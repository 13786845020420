/* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  
  .return-refund-policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .section p {
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 600px) {
    .return-refund-policy-container {
      padding: 10px;
    }
  
    .section h2 {
      font-size: 18px;
    }
  
    .section p {
      font-size: 14px;
    }
  }
  