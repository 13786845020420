/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
} */

.cookies-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.cookies-header {
    background-color: #90BC79;
    color: #fff;
    padding: 20px;
    text-align: center;
}

.cookies-header h1 {
    margin-bottom: 10px;
}

.cookies-header nav ul {
    list-style: none;
    padding: 0;
}

.cookies-header nav ul li {
    display: inline;
    margin: 0 10px;
}

.cookies-header nav ul li a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.cookies-content {
    flex: 1;
    padding: 20px;
    background-color: #fff;
}

.cookies-section {
    margin-bottom: 40px;
}

.cookies-section h2 {
    margin-bottom: 10px;
    color: #007bff;
}

.cookies-section p {
    margin-bottom: 10px;
    color: #666;
}

.cookies-section ul {
    list-style: disc;
    margin-left: 20px;
}

.cookies-footer {
    background-color: #90BC79;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ddd;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .cookies-header nav ul li {
        display: block;
        margin: 10px 0;
    }
    
    .cookies-section h2 {
        font-size: 1.5em;
    }
}
