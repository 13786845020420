.update-profile-container {
    margin-top: 20px;
    padding: 15px;
    max-width: 1200px;
    margin: auto;
}

.update-profile-form-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.update-profile-header {
    background-color: rgb(228, 0, 92);
    color: #fff;
    text-align: center;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-input {
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
}

.required {
    color: #dc3545;
}

.form-submit {
    text-align: center;
    margin-top: 20px;
}

.btn-primary {
    /* background-color: #343a40; */
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-primary:hover {
    background-color: #495057;
}

/* Media Queries */
@media (max-width: 768px) {
    .update-profile-container {
        padding: 10px;
    }

    .update-profile-form-container {
        padding: 15px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-input {
        padding: 10px;
    }

    .btn-primary {
        padding: 10px 15px;
    }
}