/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
} */

.help-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.help-header {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    
}

.help-header nav ul {
    list-style: none;
    padding: 0;
}

.help-header nav ul li {
    display: inline;
    margin: 0 10px;
}

.help-header nav ul li a {
    text-decoration: none;
    color: #333;
}

.help-content {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.help-content h2 {
    margin-bottom: 10px;
}

.faq-item {
    margin-bottom: 20px;
}

.contact a {
    color: #007bff;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}

.help-footer {
    background-color: #f8f8f8;
    text-align: center;
    padding: 10px;
    border-top: 1px solid #ddd;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .help-header nav ul li {
        display: block;
        margin: 10px 0;
    }
}
