.forget-password-section {
    padding: 40px 0;
}

.forget-password-box {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h4 {
    margin-bottom: 20px;
    color: #333;
}

label {
    font-weight: bold;
    color: #555;
    float: left;
    margin-bottom: 5px;
}

.forget-password-input {
    width: 100%;
    padding: 9px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.forget-password-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.forget-password-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.forget-password-message {
    margin: 20px 0;
    color: green;
}

@media (max-width: 768px) {
    .forget-password-box {
        width: 100%;
        padding: 15px;
    }

    .forget-password-button {
        padding: 15px;
    }
}
